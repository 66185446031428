<template>
  <div class="member-shop-feedback-activity-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="活動名稱" prop="name" align="center" />
      <el-table-column label="狀態" prop="enabled" align="center" width="100">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'enabled.tagType')">{{ get(scope.row, 'enabled.label') }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="開始時間" prop="startAt" align="center" />
      <el-table-column label="結束時間" prop="endAt" align="center" />
      <el-table-column label="單筆回饋上限" prop="rewardLimitPerTransaction" align="center" />
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onEdit(scope.row)"
            @delete="onDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteRewardActivity"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import dayjs from 'dayjs'
import { get, map } from 'lodash'
import EmptyBlock from '@/components/EmptyBlock.vue'
import Tag from '@/components/Tag/Tag.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import { DeleteMemberShopRewardActivity } from '@/api/memberShop'
import { useRouter } from 'vue-router/composables'
import store from '@/store'
import { formatDate } from '@/utils/date'
import { rewardActivityTypeConfig } from '@/config/rewardActivity'

export default defineComponent({
  name: 'MemberShopFeedbackActivityTable',
  components: { EmptyBlock, Tag, DeleteDialog },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })
    const shopId = computed(() => store.getters.shop)

    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const { id, name, enabled, startAt, endAt, rewardType, rewardLimitPerTransaction } = item

        let displayRewardLimitPerTransaction
        const rewardUnit = rewardActivityTypeConfig[rewardType].unit
        const rewardAmount = rewardLimitPerTransaction

        if (Number(rewardAmount) === 0) displayRewardLimitPerTransaction = '每筆訂單至多回饋無上限'
        else displayRewardLimitPerTransaction = `每筆訂單至多回饋 ${rewardAmount} ${rewardUnit}`

        return {
          id,
          name,
          enabled: enabled ? { label: '啟用', tagType: 'action' } : { label: '未啟用', tagType: 'info' },
          startAt: formatDate(startAt),
          endAt: formatDate(endAt),
          rewardType: `${get(rewardActivityTypeConfig, `${[rewardType]}.label`)}`,
          rewardLimitPerTransaction: displayRewardLimitPerTransaction,
        }
      })
    })

    const deleteRewardActivity = async () => {
      const [, err] = await DeleteMemberShopRewardActivity({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }

    const onDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const onEdit = (row) => {
      router.push({
        name: 'MemberShopFeedbackActivityEdit',
        params: {
          id: row.id,
        },
      })
    }

    return {
      modal,
      displayData,
      get,
      dayjs,
      onEdit,
      onDelete,
      deleteRewardActivity,
    }
  },
})
</script>
